import React from 'react'

import {
  BookIcon,
  Bullet,
  CheckIcon,
  CustomerIcon,
  Heading,
  Inline,
  Paragraph,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Color } from '../../components/Color'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Bullet"
    components={[{ component: Bullet, restElement: true }]}
  >
    <Section title="Koot">
      <Playground>
        <Inline
          gap="md"
          alignItems="end"
        >
          <Bullet size="sm" />
          <Bullet size="md" />
          <Bullet size="lg" />
          <Bullet />
          <Bullet size="xxxl" />
          <Bullet size="layout-lg" />
          <Bullet size="layout-xl" />
        </Inline>
      </Playground>
    </Section>
    <Section title="Ikonilla">
      <Paragraph>
        Ikonia ei näytetä <Code>sm</Code>-koossa.
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          alignItems="end"
        >
          <Bullet
            color="secondary"
            size="sm"
          />
          <Bullet
            color="danger"
            size="md"
            icon={<XIcon size="md" />}
          />
          <Bullet
            color="success"
            size="lg"
            icon={<CheckIcon />}
            variant="outline"
          />
          <Bullet
            color="primary"
            icon={<BookIcon />}
          />
          <Bullet
            color="primary"
            icon={<BookIcon />}
            size="xxxl"
            variant="outline"
          />
          <Bullet
            color="primary"
            icon={<BookIcon size="xxl" />}
            size="layout-lg"
          />
          <Bullet
            color="primary"
            icon={<BookIcon size="xxl" />}
            shape="square"
            size="layout-lg"
            variant="outline"
          />
          <Bullet
            color="primary-lightest"
            icon={<CustomerIcon color="primary" />}
            size="layout-xl"
          />
        </Inline>
      </Playground>
    </Section>
    <Section title="Tekstillä">
      <Paragraph>
        Ikonin sijaan voi <Code>Bullet</Code>:n sisässä näyttää esim. numeron.{' '}
        <Code>Bullet</Code>:n oikeaan yläkulmaan saa myös lisäikonin, joka tulee
        aina valkoisena <Color color="primary" />
        -taustalla.
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          alignItems="end"
        >
          <Bullet bulletContent="1" />
          <Bullet
            bulletContent="1"
            size="xxxl"
          />
          <Bullet
            badgeIcon={<CheckIcon />}
            bulletContent="1"
            size="layout-lg"
          >
            <Heading
              level={3}
              noMargin
            >
              Lorem ispum dolor
            </Heading>
          </Bullet>
        </Inline>
      </Playground>
    </Section>
  </Content>
)

export default Page
